import { render, staticRenderFns } from "./CompanyKPIs.vue?vue&type=template&id=763b6da8&scoped=true"
import script from "./CompanyKPIs.vue?vue&type=script&lang=js"
export * from "./CompanyKPIs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "763b6da8",
  null
  
)

export default component.exports